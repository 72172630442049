import {
    createRouter,
    createWebHistory
} from 'vue-router'

import axios from 'axios';
import store from '../store';

const routes = [];

// main route
let mainAppRoute = { path: '/', name: 'App', component: function () { return import('../views/App.vue') }, children: [], meta: { requiresAuth: true } };

// carrier loads
mainAppRoute.children.push({ path: '/', name: 'MyLoads', component: function () { return import('../views/MyLoads.vue') } });

// carrier invoices
mainAppRoute.children.push({ path: '/invoices', name: 'MyInvoices', component: function () { return import('../views/MyInvoices.vue') } });

// carrier drivers
mainAppRoute.children.push({ path: '/drivers', name: 'MyDrivers', component: function () { return import('../views/MyDrivers.vue') } });

// carrier units
mainAppRoute.children.push({ path: '/units', name: 'MyUnits', component: function () { return import('../views/MyUnits.vue') } });

// carrier vehicle tracking
mainAppRoute.children.push({ path: '/units/track', name: 'MyUnitsTracking', component: function () { return import('../views/UnitsLocations.vue') } });
mainAppRoute.children.push({ path: '/units/track/:id', name: 'MyTracking', component: function () { return import('../views/VehicleTrack.vue') } });



// user profile
mainAppRoute.children.push({ path: '/profile', name: 'MyProfile', component: function () { return import('../views/Profile.vue') } });

routes.push(mainAppRoute);

routes.push({ path: '/invitation/:id', name: 'Invitation', component: function () { return import('../views/Invitation.vue') } });
routes.push({ path: '/restore', name: 'Restore', component: function () { return import('../views/Restore.vue') } });
routes.push({ path: '/restore/:id', name: 'ResetPasswordPage', component: function () { return import('../views/ResetPassword.vue') } });



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});



router.beforeEach(async (to, from, next) => {


    let token = localStorage.getItem('token');
    if(to.meta && to.meta.requiresAuth) {

        if(!token) return next();
        store.commit('token', token);

        await axios.get('/user')
        .then(data => {
            store.commit('user', data.data);
        })
        .catch(err => {
            store.commit('token', null);
            store.commit('user', null);
            localStorage.removeItem('token');
        });

    }

    return next();
});
  
export default router