<template>
    <div class="dropdown-select" @click="switchView" v-click-outside="hideView" :class="{ 'active' : shown }">
        <div class="selection text-overflow">{{ selected }}</div>
        <div class="icon-holder">
            <span v-if="shown"><i class="fa-solid fa-chevron-up"></i></span>
            <span v-else><i class="fa-solid fa-chevron-down"></i></span>
        </div>
        <div class="list-window-holder" :class="[ `position-${ position || 'left' }`, shown ? 'active' : '' ]"  @click.stop>
            <div class="list-window" :class="[ shown ? 'active' : loaded ? 'hidden' : '' ]">
                <DatePicker class="date-picker" @dayclick="dayClick" :model-config="modelConfig" :minute-increment="15" trim-weeks v-model="selection" :mode="mode || 'date'" timezone="UTC" @weeknumberclick="weekNumberClicked" :is-range="isRange" show-iso-weeknumbers/>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
    export default {
        props: {
            placeholder: { type: String, required: false },
            modelValue: { required: true },
            position: { type: String },
            range: { type: Boolean },
            displayFormat: { type: String },
            mode: { type: String },
            weekly: { type: Boolean }
        },
        data() {
            return {
                shown: false,
                selection: this.modelValue || null,
                loaded: false,
                isRange: this.range ? true : false,
                _displayFormat: this.displayFormat || "MMM D, YYYY",
                modelConfig: {
                    timeAssign: '12:00:00',
                },
            }
        },
        methods: {
            dayClick(e) {

            },
            weekNumberClicked(e) {
                if(!this.range) return;
                let days = e.days;
                let newSelection = {
                    start: days[0].date,
                    end: days[6].date,
                }
                this.selection = newSelection;
            },
            hideView() {
                this.shown = false;
            },
            switchView() {
                this.shown = !this.shown;
                if(this.shown && !this.loaded) {
                    this.loaded = true;
                }
            },
            applyChanges() {
                this.$emit('update:modelValue', this.selection);
            }
        },
        computed: {
            selected() {
                if(!this.selection) return this.placeholder;
                try {
                    if(this.isRange) {
                        let start = moment.utc(this.selection.start).format(this._displayFormat);
                        let end = moment.utc(this.selection.end).format(this._displayFormat);
                        return start + " - " + end;
                    }
                    return moment.utc(this.selection).format(this._displayFormat);
                } catch(e) {
                    return this.placeholder;
                }
            },
        },
        watch: {
            modelValue() {
                this.selection = this.modelValue;
            },
            selection(newValue, oldValue) {
                this.applyChanges();
            }
        }
    }
</script>

<style lang="scss">

.vc-day .vc-opacity-0 {
  opacity: 0.5;
}

.vc-day .vc-pointer-events-none {
  pointer-events: all;
}
</style>


<style lang="scss" scoped>


@keyframes popUpAnimation {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes popUpAnimationReverse {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}


.dropdown-select {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 14px;
    column-gap: 15px;
    max-width: 220px;
    align-items: center;
    position: relative;
    padding: 0;
    z-index: 1;
    user-select: none;
    cursor: pointer;
    
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 3px;
        width: 100%;
        border-bottom: 2px solid $borderColor;
        transition: ease 0.3s;
    }
    &:hover {
        &::after {
            border-bottom: 2px solid $buttonColor;
        }
    }
    &.active {
        z-index: 2;
        &::after {
            border-bottom: 2px solid $buttonColor;
        }
    }

    .list-search {
        width: 100%;
        height: 44px;
        border-radius: 0;
        border: 0;
        padding: 0 20px;
        font-size: 12px;
        outline: none;
    }

    .icon-holder {
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .list-window-holder {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateY(20px);
        cursor: auto;
        user-select: text;
        pointer-events: none;
        &.active {
            pointer-events: auto;
        }
        &.position-center {
            left: 50%;
            transform: translate(-50%, 20px);
            right: auto;
        }
        &.position-right {
            left: auto;
            right: 0;
            transform: translateY(20px);
        }
    }

    .list-window {
        min-width: 220px;
        max-width: 350px;
        background: #fff;
        box-shadow: $boxShadow;
        border-radius: 10px;
        display: grid;
        border: 1px solid $borderColor;
        overflow: hidden;
        opacity: 0;
        transform-origin: 50% 0;
        transform: scale(0);
        pointer-events: none;
        transition: ease 0.1s;
        
        &.hidden {
            pointer-events: none;
            animation: popUpAnimationReverse 0.15s;
            animation-fill-mode: forwards;
        }
        &.active {
            pointer-events: auto;
            animation: popUpAnimation 0.2s;
            animation-fill-mode: forwards;
        }
       
    }

}

.date-picker {
    border: 0;
    background: transparent;
}

</style>