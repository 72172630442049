import { createStore } from 'vuex'

export default createStore({
    state: {
        user: null,
        token: null,
        axiosLoaders: [],
        loadManager: {
            viewLoad: null
        },
        numberOfModals: 0,
        states: ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        token: (state) => {
            return state.token;
        },
        axiosLoaders: (state) => {
            return state.axiosLoaders;
        },
        loadManager: (state) => {
            return state.loadManager;
        },
        numberOfModals: (state) => {
            return state.numberOfModals;
        },
        states: (state) => {
            return state.states;
        }
    },
    mutations: {
        token: (state, payload) => {
            state.token = payload;
        },
        user: (state, payload) => {
            state.user = payload;
        },
        numberOfModals: (state, payload) => {
            state.numberOfModals = payload;
        }
    },
    actions: {

    },
    modules: {

    }
})
