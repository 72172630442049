<template>
    <div class="input">
        <div class="_header">
            <label>{{ name }}</label>
            <span class="_error">{{ error }}</span>
        </div>
        <div class="inputHolder" :class="{ 'withCopyBtn' : copy }">
            <input :type="type" v-model="content"  :placeholder="placeholder" @input="$emit('valueChanged')" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled">
            <button v-if="copy" class="default small reverse copyBtn" @click="copyValue" v-tooltip="copyText" :onmouseout="() => { copyText = 'Copy' }">
                <span><i class="fas fa-copy"></i></span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['name', 'value','modelValue', 'type', 'placeholder', 'error', 'disabled', 'copy'],
        data() {
            return {
                content: this.modelValue || this.value || '',
                copyText: 'Copy'
            }
        },
        methods: {
            copyValue() {
                this.copyText = "Copied";
                this.copyTextToClipboard(this.content);
            }
        },
        watch: {
            content(newValue, oldValue) {
                this.$emit('update:modelValue', this.content);
            },
            modelValue() {
                this.content = this.modelValue;
            }
        },
    }
</script>

<style lang="scss">
.inputHolder {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    column-gap: 10px; 
    &.withCopyBtn {
        grid-template-columns: minmax(0, 1fr) auto;
        .copyBtn {
            height: 100%;
            position: relative;
        }
    }
}
</style>